.dashboard-alarms{
    height: 100%;

    &.loading-skeleton{
        background: linear-gradient(-90deg, #eee 0%, #d7d7d7 50%, #eee 100%);
        background-size: 400% 400%;
        animation: pulse 1.2s ease-in-out infinite;
    }

    .dashboard-alarms-heading{
        height: 100px;
        background: linear-gradient(45deg, #2F80ED, #56CCF2);
        color: white;
        padding: 10px 20px;
        display: flex;
        align-items: center;
        justify-content: center;

        .next-alarm{
            flex: 3;
            min-width: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            font-weight: bold;

            .label, .pill-names{
                margin: 5px 0;
                font-size: 0.85em;
                text-shadow: 0 2px rgba(128, 128, 128, 0.3);
            }
            .pill-names{
                text-transform: capitalize;
                max-width: 60%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

            }
            .time{
                font-size: 1.5em;
                text-shadow: 0 2px rgba(128, 128, 128, 0.3);
            }
        }
        .alarm-box{
            flex: 1;
            min-width: 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: column;

            .medper{
                display: grid;
                grid-template-columns: repeat(8, 1fr);
                gap: 2px;
                margin: 5px 0;

                .box{
                    width: 10px;
                    height: 30px;
                    border-radius: 2px;
                    border: 2px solid #ffffff;
                    box-shadow: 0px 2px 5px #888888;

                    &.highlight{
                        background-color: #ffffff;
                    }
                }
            }

            .box-numbers{
                font-size: 0.8em;
                margin: 5px 0;
                text-shadow: 0 2px 10px rgba(128, 128, 128, 1);
            }
        }
    }

    .dashboard-alarms-content{
        height: calc(100% - 170px);
        min-height: 200px;
        
        &.expanded{
            height: calc(100% - 100px);
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        .upcoming-alarms-heading{
            width: fit-content;
            padding: 10px 30px;
            background: linear-gradient(90deg, #f3f3f3 0%, #ffffff 100%);
            border-bottom: 2px solid #cacaca;
        }

        .upcoming-alarms-list{
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: calc(100% - 40px);

            .next-alarm-item{
                display: flex;
                align-items: center;
                justify-content: center;
                height: 50px;
                .time{
                    flex: 2;
                    min-width: 0;
                    text-align: center;
                    font-size: 1.5em;
                    font-weight: bold;
                }
                .pill-names{
                    flex: 3;
                    min-width: 0;
                    font-size: 0.8em;
                    max-width: 90%;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                .box-numbers{
                    flex: 1;
                    min-width: 0;
                    font-size: 0.8em;
                    text-align: center;
                    max-width: 90%;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }

    }

    .dashboard-alarms-footer{
        height: 70px;
        //border-top: 2px solid black;
        background-color: #f3f3f3;
        border-top: 2px solid #cacaca;
    }

    .no-alarms{
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: #f3f3f3;
    }

    .dashboard-alarms-content, .dashboard-alarms-footer, .no-alarms{
        p{
            margin: 10px 0 5px 0;
            text-align: center;
            font-size: 0.8em;
            font-weight: bold;
            opacity: 0.5;
        }
        button{
            display: block;
            width: auto;
            min-width: 70px;
            height: 35px;
            padding: 0 10px;
            margin: 0 auto;
            background-color: transparent;
            border: 0px;
            border-radius: 5px;
            cursor: pointer;
            transition: all .2s ease-out 0s;
            color: #007aff;
            &:hover{
                background-color: rgba($color: #007aff, $alpha: 0.25);
            }
        }
    }
}