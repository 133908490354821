@use '../../variables';

.profile-close-account{
    padding: 20px;

    .form-group {
        margin: 20px auto;
        @include variables.md {
            width: 60%;
        }

        .label {
            display: flex;
            align-items: center;
            justify-content: space-between;
            
            .text{
                font-size: 0.8em;
                font-weight: bold;
            }
            .icon {
                display: flex;
                align-items: center;

                .material-icons-outlined {
                    font-size: 1em;
                    font-weight: bold;
                    margin-left: 5px;
                }
            }
        }

        .warning-text{
            padding: 20px 0;
            font-size: 0.8em;
            text-align: justify;

            span.highlight{
                font-weight: bold;
                color: red;
                margin-right: 5px;
            }

            .check-to-continue{
                padding: 10px 0;
            }
        }
    }

    .button-row{
        margin-top: 50px;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        justify-content: center;

        .spinner{
            margin: 0 auto;
        }
    }
}

.active-device-modal-content{
    width: 50vw;
    padding: 20px 20px 10px 20px;

    hr{
        color: grey;
        background: grey;
        border: 0;
        height: 2px;
        margin: 10px 0;
    }

    .instructions{
        background-color: #feffbb;
        font-size: 0.8em;
        text-align: justify;
        padding: 10px;
        border-radius: 5px;
    }

    .device-list{
        margin: 10px 0;
        max-height: 250px;
        overflow-y: auto;

        .device-item{
            border: 1px solid black;
            margin: 10px 0;
            .item-card{
                padding: 10px 20px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .device-name{
                    .nickname{
                        font-size: 0.9em;
                        font-weight: bold;
                    }
                    .id{
                        font-size: 0.8em;
                    }
                }
                .activated-time{
                    font-size: 0.7em;
                    .label{
                        font-weight: bold;
                    }
                }
            }
            .actions-dropdown{
                display: flex;
                align-items: center;
                justify-content: space-around;
                padding: 5px;
                background-color: #dcdcdc;

                button{
                    padding: 5px 20px;
                    color: #2b2b2b;
                    font-weight: bold;
                    display: flex;
                    align-items: center;
                    text-align: center;
                    cursor: pointer;
                }
            }
        }
    }

    .active-device-modal-content-footer{
        display: flex;
        align-items: center;
        justify-content: space-between;

        button{
            width: auto;
            min-width: 70px;
            height: 35px;
            padding: 0 10px;
            background-color: #FFF;
            border: 0px;
            border-radius: 5px;
            cursor: pointer;
            transition: all 0.2s ease-out 0s;

            &.cancel{
                color: rgb(128, 128, 128);
                &:hover{
                    background-color: rgba(128, 128, 128, 0.25);
                }
            }
            &.save{
                color: rgb(0, 122, 255);
                &:hover{
                    background-color: rgba(0, 122, 255, 0.25);
                }
            }
        }
    }
}

.confirm-close-account-modal-content{

    width: 20vw;
    padding: 20px 20px 10px 20px;

    hr{
        color: grey;
        background: grey;
        border: 0;
        height: 2px;
        margin: 10px 0;
    }

    .confirm-close-account-modal-footer{
        display: flex;
        align-items: center;
        justify-content: space-between;

        button{
            width: auto;
            min-width: 70px;
            height: 35px;
            padding: 0 10px;
            background-color: #FFF;
            border: 0px;
            border-radius: 5px;
            cursor: pointer;
            transition: all 0.2s ease-out 0s;

            &.cancel{
                color: rgb(128, 128, 128);
                &:hover{
                    background-color: rgba(128, 128, 128, 0.25);
                }
            }
            &.confirm{
                color: rgb(255, 0, 0);
                &:hover{
                    background-color: rgba(255, 0, 0, 0.25);
                }
            }
        }
    }
}