@use "../../variables";

.settings-page {
  padding: 20px;

  > h2 {
    margin: 20px auto;
    @include variables.md {
      width: 60%;
    }
  }

  .form-group {
    margin: 20px auto;
    @include variables.md {
      width: 60%;
    }

    .label {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .text {
        font-size: 0.8em;
        font-weight: bold;
      }
      .visibility {
        display: flex;
        align-items: center;
        .material-icons-outlined {
          font-size: 1em;
          font-weight: bold;
          margin-left: 5px;
        }
      }
    }
    .input-field {
      margin: 5px 0;
      padding: 10px 20px;
      width: 100%;
      border: 1px solid rgb(118, 118, 118);
      border-radius: 2px;

      &:disabled {
        cursor: not-allowed;
      }

      &.input-name {
        &.empty {
          border-color: red;
        }
      }

      &.input-gender {
        padding: 10px 0;
        border: none;
        display: flex;
        align-items: center;
        justify-content: space-around;

        .radio-label-container {
          display: grid;
          grid-template-columns: 1em auto;
          gap: 0.5em;
          font-size: 0.8em;
          cursor: pointer;
        }

        input[type="radio"] {
          /* Remove most all native input styles */
          appearance: none;
          /* For iOS < 15 */
          background-color: var(--form-background);
          /* Not removed via appearance */
          margin: 0;

          font: inherit;
          width: 1.15em;
          height: 1.15em;
          border: 2px solid black;
          border-radius: 50%;
          transform: translateY(-0.075em);

          display: grid;
          place-content: center;
          cursor: pointer;

          &::before {
            content: "";
            width: 0.65em;
            height: 0.65em;
            border-radius: 50%;
            transform: scale(0);
            transition: 120ms transform ease-in-out;
            box-shadow: inset 1em 1em #1a73e8;
          }
          &:checked::before {
            transform: scale(1);
          }
        }
      }

      &.input-phone {
        padding: 0;

        input {
          width: 100%;
          height: auto;
          line-height: normal;
          padding: 10px 20px 10px 48px;
          border: none;
        }
        .flag-dropdown {
          border-left: none;
          border-top: none;
          border-bottom: none;
        }
      }
    }
  }

  .button-row {
    margin-top: 50px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    .spinner {
      margin: 0 auto;
    }
  }
}
