.profile-image{
    padding: 20px;

    .form-group{
        margin: 20px auto;
        width: 60%;

        .label{
            display: flex;
            align-items: center;
            justify-content: space-between;
            .text{
                font-size: 0.8em;
                font-weight: bold;
            }
            .visibility{
                display: flex;
                align-items: center;
                .material-icons-outlined{   
                    font-size: 1em;
                    font-weight: bold;
                    margin-left: 5px;
                }
            }
        }
        .img-container{
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 30px;
        }
    }

    .button-row{
        margin-top: 20px;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        justify-content: center;

        .spinner{
            margin: 0 auto;
        }
    }
}

.change-image-modal-content{
    width: 50vw;
    padding: 20px 20px 10px 20px;

    hr{
        color: grey;
        background: grey;
        border: 0;
        height: 2px;
        margin: 10px 0;
    }

    .cropper-wrapper{
        height: 300px;
        position: relative;
    }
    
}