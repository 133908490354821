.schedule{

    .timezone-info{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        background-color: rgba($color: #1a73e8, $alpha: 0.25);
        border-radius: 10px;
        margin: 20px auto 10px auto;
        padding: 0 20px 0 15px;
        width: fit-content;

        .material-icons-outlined{
            margin: 10px;
            font-size: xx-large;
            color: #1a73e8;
        }
        .timezone-info-text{
            margin: 10px;
            color: rgba(26, 115, 232, 1);
            
            .timezone-info-text-sub{
                color: rgba(26, 115, 232, 0.75);
                margin-top: 2px;
            }
        }
    }

    .card-list {
        justify-content: center;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
        gap: 20px;
        grid-auto-rows: 1fr;
        padding: 20px;

        .card {
            display: flex;
            flex-direction: column;
            box-shadow: 0px 0px 8px #888888;
            border-radius: 5px;
            min-height: 400px;
            background-color: #EEEEEE;

            .card-header{
                width: 100%;
                padding: 5px 20px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;

                .card-info-head{
                    font-size: 1.5em;
                    font-weight: bold;
                    width: 100%;
                    margin: 10px 0;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            .card-info{
                width: 100%;
                padding: 5px 20px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .card-info-value{
                    width: 3em;
                    padding: 1px 5px;
                    font-size: 1.2em;
                    font-weight: bold;
                    text-align: center;
                }
            }

            .card-alarms{
                width: 100%;
                height: 200px;
                flex-grow: 1;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin: 10px 0;

                .card-alarm-time{
                    width: 100%;
                    padding: 5.5px 5px;
                    font-size: 1.2em;
                    font-weight: bold;
                    text-align: center;
                }
            }

            .card-empty{
                flex-grow: 1;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                .card-empty-message{
                    margin: 50px 0;
                }

                .button-blue{
                    width: auto;
                    min-width: 70px;
                    height: 35px;
                    padding: 0 10px;
                    background-color: transparent;
                    border: 0px;
                    border-radius: 5px;
                    cursor: pointer;
                    transition: all .2s ease-out 0s;
                    color: #007aff;
                    &:hover{
                        background-color: rgba($color: #007aff, $alpha: 0.25);
                    }
                }
            }

            .card-footer{
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-weight: bold;
                border-bottom-left-radius: 5px;
                border-bottom-right-radius: 5px;
                height: 30px;
                width: 100%;
                position: relative;
                
                .box-name-wrapper{
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    padding: 5px 20px 5px 5px;
                    background-color: #1a73e8;
                    color: #FFFFFF;
                    //clip-path: polygon(0 0, 80% 0, 100% 100%, 0 100%);
                    clip-path: polygon(0 0, 80% 0, 100% 50%, 80% 100%, 0 100%);
                    border-bottom-left-radius: 5px;
                }

                .card-edit-button{
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    width: 100%;
                    height: 100%;
                    padding: 0 25px 0 15px;
                    border: 0px;
                    border-bottom-right-radius: 5px;
                    cursor: pointer;
                    transition: all .2s ease-out 0s;
        
                    .material-icons-outlined{
                        margin-right: 5px;
                    }
        
                    &:hover {
                        background-color: #BBBBBB;
                        color: #000000;
                    }
                }
            }
        }
    }
}