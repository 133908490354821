.devices{
    min-height: 90vh;
    padding: 20px;

    .invite-bar{
        width: 100%;
        border-radius: 5px;
        display: flex;
        align-items: center;
        height: 50px;
        background-color: #acdffe;
        justify-content: space-between;

        .material-icons-outlined{
            margin: 0 10px;
        }

        h4{
            flex-grow: 1;
        }

        .open-invitation-button{
            width: auto;
            min-width: 70px;
            height: 35px;
            padding: 0 10px;
            margin: 0 20px;
            background-color: transparent;
            border: 2px solid transparent;
            border-radius: 5px;
            cursor: pointer;
            transition: all .2s ease-out 0s;
            color: #007aff;
            &:hover{
                background-color: rgba($color: #007aff, $alpha: 0.25);
                border: 2px solid #007aff;
            }
        }
    }
   
    .devices-header{
        font-size: 1em;
        font-weight: bold;
        width: 100%;
        margin: 15px 0;
    } 

    .devices-container{
        justify-content: center;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
        grid-gap: 20px;
        grid-auto-rows: 1fr;
        padding: 20px 0;
        
        .device-card{
            display: flex;
            flex-direction: column;
            box-shadow: 0px 0px 8px #888888;
            border-radius: 10px;
            padding: 20px;
            height: 180px;
            cursor: pointer;

            &.centered{
                align-items: center;
            }

            h4{
                margin: 0;
            }

            .image{
                margin: 25px 0;
            }

            &:hover{
                background-color: #EEEEEE;
            }

            .card-info-icon-container{
                margin: auto 0 0 auto;
                display: flex;

                .card-info-icon{
                    height: 25px;
                    width:  25px;
                    border-radius: 50%;
                    margin: 5px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    &.red{
                        background-color: #f32155;
                    }
                    &.green{
                        background-color: #00c090;
                    }

                    .material-icons-outlined{
                        font-size: 16px;
                        color: #EEEEEE;
                    }
                }
            }
        }
    }
}

.invitations-modal-content{
    width: 400px;
    padding: 20px 20px 10px 20px;

    .invitations-modal-body{
        display: flex;
        flex-direction: column;
        align-items: center;

        .invitations-carousel{
            display: flex;
            align-items: center;
            width: 100%;

            .invitation-details{
                // flex-grow: 1;
                flex: 1 0 auto;
                margin: 0 10px;

                .invited-by{
                    display: flex;

                    .invited-by-info{
                        flex: 1 0 auto;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-evenly;

                        .invited-by-name{
                            text-overflow: ellipsis;
                            text-transform: capitalize;
                            min-width: 0;
                        }

                        .invited-by-email{
                            color: #444444;
                            text-overflow: ellipsis;
                            text-transform: lowercase;
                        }
                    }
                }

                input[type="text"]{
                    width: 100%;
                    border: none;
                    padding: 10px;
                    outline: none;
                    background: transparent;
                    background-color: #EEEEEE;
                    
                    &:hover, &:focus{
                        background-color: #BBBBBB;
                    }
                }

                .invite-info{
                    margin: 5px 0 20px 50px;
                    font-size: 0.83em;
                }
            }

            .material-icons-outlined{
                padding: 5px;
                border-radius: 50%;
                background-color: #EEEEEE;

                &:hover{
                    background-color: #BBBBBB;  
                    cursor: pointer; 
                }

                &.disabled:hover{
                    background-color: #EEEEEE;
                    cursor: not-allowed; 
                }
            }
        }
    }

    .invitations-modal-footer{
        display: flex;
        align-items: center;
        justify-content: space-between;

        button{
            width: auto;
            min-width: 70px;
            height: 35px;
            padding: 0 10px;
            background-color: #FFF;
            border: 0px;
            border-radius: 5px;
            cursor: pointer;
            transition: all .2s ease-out 0s;

            &.button-cancel{
                color: grey;
                &:hover{
                    background-color: rgba($color: grey, $alpha: 0.25);
                }
            }
            &.button-accept{
                color: #007aff;
                &:hover{
                    background-color: rgba($color: #007aff, $alpha: 0.25);
                }
            }
            &.button-reject{
                color: red;
                &:hover{
                    background-color: rgba($color: red, $alpha: 0.25);
                }
            }
        }
    }

    hr {
        color: grey;
        background: grey;
        border: 0;
        height: 2px;
        margin: 10px 0;
    }
}

.add-new-device-modal-content{
    width: 400px;
    padding: 20px 20px 10px 20px;

    .add-new-device-modal-body{

        .device-id-form{
            display: flex;
            align-items: center;

            .group{
                flex-grow: 1;
                padding-right: 10px;
                border-right: 2px solid grey;
            }
        }

        .material-icons-outlined{
            padding: 5px;
            margin-left: 10px;
            border-radius: 5px;
            background-color: #EEEEEE;

            &:hover{
                background-color: #BBBBBB;  
                cursor: pointer; 
            }

            &.disabled:hover{
                background-color: #EEEEEE;
                cursor: not-allowed; 
            }
        }
    }

    .add-new-device-modal-footer{
        display: flex;
        align-items: center;
        justify-content: space-between;

        button{
            width: auto;
            min-width: 70px;
            height: 35px;
            padding: 0 10px;
            background-color: #FFF;
            border: 0px;
            border-radius: 5px;
            cursor: pointer;
            transition: all .2s ease-out 0s;

            &.button-cancel{
                color: grey;
                &:hover{
                    background-color: rgba($color: grey, $alpha: 0.25);
                }
            }
            &.button-add{
                color: #007aff;
                &:hover{
                    background-color: rgba($color: #007aff, $alpha: 0.25);
                }
            }
        }
    }

    hr {
        color: grey;
        background: grey;
        border: 0;
        height: 2px;
        margin: 10px 0;
    }
}