.dashboard-notifications-list{
    display: flex;
    flex-direction: column;
    height: 100%;

    &.loading-skeleton{
        background: linear-gradient(-90deg, #eee 0%, #d7d7d7 50%, #eee 100%);
        background-size: 400% 400%;
        animation: pulse 1.2s ease-in-out infinite;
    }

    .dashboard-notifications-list-heading{
        height: 100px;
        display: flex;
        background: linear-gradient(45deg, #2F80ED, #56CCF2);
        color: white;

        .selected-date{
            flex-grow: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: bold;
            
            .relative-string{
                text-transform: capitalize;
                font-size: 0.85em;
                text-shadow: 0 2px rgba(128, 128, 128, 0.3);
            }
            .date-month{
                margin: 0 10px;
                font-size: 1.5em;
                text-shadow: 0 2px rgba(128, 128, 128, 0.3);
            }
        }

        button{
            padding: 5px 10px;
            margin: 5px;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: transparent;
            border: 0px;
            border-radius: 5px;
            color: #eaeaea;
            
            &:hover{
                color: #fafafa;
            }

            &.disabled{
                cursor: not-allowed;
                color: #808080;
            }
        }
    }

    .dashboard-notifications-list-content{
        margin: auto 0;
        min-height: 200px;
        max-height: calc(100vh - 220px);
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;

        p{
            text-align: center;
        }

        .notification-item{
            display: flex;
            align-items: center;
            width: 100%;
            height: 50px;
            padding: 0 20px;
            margin: 10px 0;
    
            .notification-item-time{
                flex: 1 0 0;
                text-align: center;
            }

            .notification-item-color{
                flex: 0 0 5px;
                height: 100%;
                margin: 10px;
                border-radius: 5px;
                background-color: #007aff;
                box-shadow: 0px 0px 5px #007aff;
                &.taken{
                    background-color: #22df6b;
                    box-shadow: 0px 0px 4px #22df6b;
                }
                &.missed{
                    background-color: #ff2e5f;
                    box-shadow: 0px 0px 4px #ff2e5f;
                }
            }
    
            .notification-item-message{
                flex: 4 0 0;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
}