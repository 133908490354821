.notifications{
    min-height: 90vh;
    padding: 20px;

    .notifications-filter-menu{
        display: flex;
        justify-content: space-between;
        margin: 10px 0;

        .notifications-menu-filterbadges{
            display: flex;
            padding: 0 25px 0 15px;
            flex-grow: 1;
            flex-wrap: wrap;
        }

        .notifications-menu-buttons{
            display: flex;

            .notifications-button{
                display: flex;
                align-items: center;
                width: auto;
                height: 40px;
                padding: 0 25px 0 15px;
                background-color: #EEEEEE;
                border: 0px;
                cursor: pointer;
                transition: all .2s ease-out 0s;
    
                .material-icons-outlined{
                    margin-right: 5px;
                }
    
                &:hover {
                    background-color: #BBBBBB;
                    color: #000000;
                }
            }
        }
    }
    .notifications-table-content{
        width: 100%;
        margin-top: 10px;

        .notification-group-date{
            font-weight: bold;
            padding: 10px;
            position: relative;

            &::before{
                content : "";
                position: absolute;
                left    : 0;
                bottom  : 0;
                height  : 1px;
                width   : 25%;
                border-bottom: 5px solid #007aff;
            }
        }

        .notification-item{
            display: flex;
            width: 100%;
            padding: 15px;

            .notification-item-time{
                text-align: center;
                flex: 1 0 auto;
            }

            .notification-item-message{
                flex: 5 0 auto;
            }
        }

        .spinner{
            margin: 30px auto;
        }

        .notifications-show-more-wrapper{
            display: flex;
            justify-content: center;
            
            button{
                width: auto;
                min-width: 70px;
                height: 35px;
                padding: 0 10px;
                margin: 30px auto;
                background-color: #FFF;
                border: 0px;
                border-radius: 5px;
                cursor: pointer;
                transition: all .2s ease-out 0s;
    
                &.notifications-show-more{
                    color: #007aff;
                    &:hover{
                        background-color: rgba($color: #007aff, $alpha: 0.25);
                    }
                }
            }
        }

    }

    hr {
        color: grey;
        background: grey;
        border: 0;
        height: 1px;
    }
}

.notifications-modal-content{
    width: 400px;
    padding: 20px 20px 10px 20px;

    .sort-modal-body{
        min-height: 100px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        select{
            padding: 5px;
            border: 2px solid gray;
            border-radius: 5px;
        }
    }

    .sort-modal-footer{
        display: flex;
        align-items: center;
        justify-content: space-between;

        button{
            width: auto;
            min-width: 70px;
            height: 35px;
            padding: 0 10px;
            background-color: #FFF;
            border: 0px;
            border-radius: 5px;
            cursor: pointer;
            transition: all .2s ease-out 0s;

            &.button-cancel{
                color: grey;
                &:hover{
                    background-color: rgba($color: grey, $alpha: 0.25);
                }
            }
            &.button-apply{
                color: #007aff;
                &:hover{
                    background-color: rgba($color: #007aff, $alpha: 0.25);
                }
            }
        }
    }

    .filter-modal-body{
        //width: 100%;

        .filter-group{
            display: flex;
            margin: 15px 0;

            .filter-group-name{
                flex: 1 0 100px;
            }
            .filter-group-filters{
                display: flex;
                flex-wrap: wrap;

                .filter-item{
                    flex: 1 0 100px;

                    input{
                        margin-right: 5px;
                    }
                }
            }
        }
    }

    .filter-modal-footer{
        display: flex;
        align-items: center;
        justify-content: space-between;

        button{
            width: auto;
            min-width: 70px;
            height: 35px;
            padding: 0 10px;
            background-color: #FFF;
            border: 0px;
            border-radius: 5px;
            cursor: pointer;
            transition: all .2s ease-out 0s;

            &.button-clear{
                color: red;
                &:hover{
                    background-color: rgba($color: red, $alpha: 0.25);
                }
            }
            &.button-cancel{
                color: grey;
                &:hover{
                    background-color: rgba($color: grey, $alpha: 0.25);
                }
            }
            &.button-apply{
                color: #007aff;
                &:hover{
                    background-color: rgba($color: #007aff, $alpha: 0.25);
                }
            }
        }
    }


    hr {
        color: grey;
        background: grey;
        border: 0;
        height: 2px;
        margin: 10px 0;
    }
}