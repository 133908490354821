.custom-button {
  min-width: 150px;
  width: auto;
  height: 50px;
  letter-spacing: 0.5px;
  padding: 0 35px 0 35px;
  font-size: 15px;
  background-color: #000000;
  color: #FFFFFF;
  text-transform: uppercase;
  font-weight: bolder;
  border: 2px solid #000000;
  cursor: pointer;
  transition: all .2s ease-out 0s;

  &:hover {
    background-color: #FFFFFF;
    color: #000000;
  }

  &:focus{
    outline-color: #000000;
  }

  &.inverted{
    background-color: #FFFFFF;
    color: #000000;

    &:hover {
      background-color: #000000;
      color: #FFFFFF;
    }
  
    &:focus{
      outline-color: #FFFFFF;
    }
  }
}
