@use '../../variables';

.forgot-password-page-background{
    height: 100vh;
    // @include xl {
        //background-color: #0EE865;//rgb(255, 202, 103);
        //background: linear-gradient(90deg, #39B54A 6.34%, #27AAE1 61.51%, #662D91 100%);
        background: linear-gradient(125deg, #39B54A 6.34%, #27AAE1 61.51%, #662D91 100%);
        background-size: 300% 300%;
        animation: gradient 20s ease infinite;
        min-height: 700px;
        height: 100vh;
    // }
    @keyframes gradient {
        0% {
            background-position: 0% 50%;
        }
        50% {
            background-position: 100% 50%;
        }
        100% {
            background-position: 0% 50%;
        }
    }
    display: flex;
    justify-content: center;
    align-items: center;

    .forgot-password-page-content {
        width: calc(100vw - 30px);
        padding: 10px 10px;
        @include variables.md {
            width: min(440px, 50vw);
        }
        background-color: white;
        position: relative;

        .title{
            margin-top: 0;
        }

        #forgot-password-button{
            display: block;
            margin: auto;
        }

        .disable-form{
            background-color: #ffffff;
            opacity: 0.5;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            cursor: not-allowed;
        }
    }
}