.header {
  width: 100%;
  height: 60px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #f3f3f3;
  border-bottom: 2px solid #cacaca;
  box-shadow: 0px 2px 10px 0px #c0c0c0;

  &.show-border {
    background-color: #f3f3f3;
    border-bottom: 2px solid #cacaca;
    box-shadow: 0px 2px 10px 0px #c0c0c0;
  }

  button.menu-icon,
  button.device-selector {
    padding: 5px 10px;
    margin: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .device-selector {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    .device-nickname {
      width: 90px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      text-align: left;
    }

    .dropdown {
      position: absolute;
      top: 50px;
      right: 00px;
      background-color: #fff;
      border: 2px solid #555;
      border-radius: 10px;
      outline: none;
      z-index: 20;
      padding: 5px 0;
      width: 120px;

      .dropdown-menuitem {
        display: flex;
        align-items: center;
        padding: 5px 10px;
        margin: 2px 0;
        text-align: left;
        font-size: 0.83em;
        cursor: pointer;

        &:hover {
          background-color: #bbbbbb;
        }

        .material-icons-outlined {
          font-size: 18px;
          margin-right: 5px;
        }
      }
      &::after {
        content: "";
        position: absolute;
        display: block;
        width: 0px;
        right: 0px;
        top: -10px;
        border: 10px solid transparent;
        border-top: 0;
        border-bottom: 10px solid #555;
        transform: translateX(calc(-50% - 5px));
      }
    }
  }

  .header-logo {
    height: 40px;
    min-height: 40px;
    max-height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    .medper-logo {
      max-width: 180px;
      min-width: 180px;
      max-height: 40px;
      min-height: 40px;
    }
  }

  .header-user {
    cursor: pointer;
    position: relative;

    &:hover {
      .avatar {
        border: 2px solid rgba(210, 215, 211);
      }
    }

    .dropdown {
      position: absolute;
      top: 60px;
      right: 00px;
      background-color: #fff;
      border: 2px solid #555;
      border-radius: 10px;
      outline: none;
      z-index: 20;
      padding: 5px 0;
      width: 120px;

      .dropdown-menuitem {
        display: flex;
        align-items: center;
        padding: 5px 10px;
        margin: 2px 0;
        text-align: left;
        font-size: 0.83em;
        cursor: pointer;

        &:hover {
          background-color: #bbbbbb;
        }

        .material-icons-outlined {
          font-size: 18px;
          margin-right: 5px;
        }
      }
      &::after {
        content: "";
        position: absolute;
        display: block;
        width: 0px;
        right: 0px;
        top: -10px;
        border: 10px solid transparent;
        border-top: 0;
        border-bottom: 10px solid #555;
        transform: translateX(calc(-50% - 5px));
      }
    }
  }

  .spacer {
    flex-grow: 10;
  }
}
