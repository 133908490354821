.dashboard {
    font-family: Arial, Helvetica, sans-serif;
    min-height: calc(100% - 60px);
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(450px, 1fr));
    @media (max-width: 550px) {
        grid-template-columns: 100%;
    }
    @media (min-width: 1500px) {
        grid-template-columns: 1fr 1fr;
    }
    grid-template-rows: min-content;
    grid-gap: 20px;
    padding: 20px;

    .dashboard-card{
        box-shadow: 0px 0px 8px #888888;
        border-radius: 5px;
        overflow: hidden;

        &.loading-skeleton {
            width: 100%;
            height: 100%;
            background: linear-gradient(-90deg, #eee 0%, #d7d7d7 50%, #eee 100%);
            background-size: 400% 400%;
            animation: pulse 1.2s ease-in-out infinite;
        }
          
        @keyframes pulse {
            0% {
                background-position: 0 0;
            }
            100% {
                background-position: -135% 0;
            }
        }
    }
    
    .stats-container{
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        grid-gap: 20px;
        
        .timestamp-container{
            grid-column: span 2;
            display: flex;
            align-items: center;

            &.true{
                background: linear-gradient(45deg, #00c090, #a0ebcf);
                .material-icons-outlined{
                    color: #00c090;
                }
            }
            &.false{
                background: linear-gradient(45deg, #ff0844, #ffb199);
                //#ff0844→#ffb199
                .material-icons-outlined{
                    color: #c01a00;
                }
            }

            .icon{
                width: 40px;
                height: 40px;
                border-radius: 50%;
                margin: 0 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: white;
            }
            h3{
                color: white;
                text-shadow: 0 2px rgba(128, 128, 128, 0.3);
                margin: 5px 0;
            }
        }

        .count-container{
            padding: 10px 20px;
            .stats-count{
                flex-grow: 1;
                font-size: 3em;
                font-weight: bolder;
                filter: drop-shadow(0 2px rgba(128, 128, 128, 0.3));
                &#taken-count{
                    background: linear-gradient(45deg, #00c090, #a0ebcf);
                    background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
                &#missed-count{
                    background: linear-gradient(45deg, #c01a00, #dd6f47);
                    background-clip: text;
                    -webkit-text-fill-color: transparent;
                }

            }
            .stats-label{
                font-size: 1em;
                font-weight: bold;
            }
            .stats-date{
                text-transform: capitalize;
                font-size: 0.7em;
                font-weight: bold;
                opacity: 0.5;
            }
        }
    }
}