@use '../../variables';

.profile{
    font-family: Arial, Helvetica, sans-serif;
    min-height: calc(100% - 80px);
    margin: 0 auto;
    width: calc(100vw - 30px);
    @include variables.md {
        width: 60vw;
    }

    .profile-header{
        display: flex;
        align-items: center;
        margin: 20px 0;

        button{
            padding: 5px 20px;
            color: grey;
            font-weight: bold;
            display: flex;
            align-items: center;
            text-align: center;
            cursor: pointer;

            .button-text{
                display: none;
                @include variables.md {
                    margin-left: 10px;
                    display: block;
                }
            }
        }

        span.tab-heading{
            flex-grow: 1;
            font-size: 1.5em;
            font-weight: bold;
            text-align: center;
        }
    }

    .profile-layout{
        border: 1px solid black;
        box-shadow: 0px 0px 8px #888888;
        display: block;
        @include variables.md {
            display: flex;
        }
        
        .profile-side-tabs{
            flex: 1;
            min-width: 0;
            background: #121212;
            color: #FFFFFF;
            font-size: 0.8em;
            display: none;
            @include variables.md {
                display: block;
            }

            .tab{
                padding: 10px 20px;
                cursor: pointer;
                &.highlighted{
                    background-color: #2a2a2a;
                    border-right: 10px solid #1a73e8;
                }
                &:hover{
                    background-color: #2a2a2a;
                }
            }
        }
        .profile-dropdown-tabs{
            position: relative;
            background-color: #121212;
            color: #FFFFFF;
            display: block;
            @include variables.md {
                display: none;
            }

            .profile-dropdown-tabs-header{
                height: 50px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .current-tab{
                    margin: 0 20px;
                }
                button{
                    padding: 5px 5px;
                    color: #FFFFFF;
                    background-color: transparent;
                    border: none;
                    display: flex;
                    align-items: center;
                    text-align: center;
                    cursor: pointer;
                }
            }
            .profile-dropdown-tabs-container{
                position: absolute;
                top: 50px;
                background-color: #121212;
                width: 100%;

                .tab{
                    padding: 10px 20px;
                    cursor: pointer;
                    &:hover{
                        background-color: #2a2a2a;
                    }
                }
            }

        }
        .profile-content{
            flex: 4;
            min-width: 0;
        }
    }
}