@use '../../variables';

.signin-page-background{
    height: 100vh;
    // @include xl {
        background: linear-gradient(125deg, #39B54A 6.34%, #27AAE1 61.51%, #662D91 100%);
        background-size: 300% 300%;
        animation: gradient 20s ease infinite;
        min-height: 700px;
        height: 100vh;
    // }
    @keyframes gradient {
        0% {
            background-position: 0% 50%;
        }
        50% {
            background-position: 100% 50%;
        }
        100% {
            background-position: 0% 50%;
        }
    }
    display: flex;
    justify-content: center;
    align-items: center;

    .signin-page-content {
        width: calc(100vw - 30px);
        padding: 10px 10px;
        @include variables.md {
            width: min(440px, 50vw);
        }
        background-color: white;
        position: relative;

        .title{
            margin-top: 0;
        }

        #sign-in-button{
            display: block;
            margin: auto;
        }

        .supporting-links-container{
            display: flex;
            justify-content: flex-end;
            padding-top: 2em;

            h5{
                cursor: pointer;
                &:hover{
                    text-decoration: underline;
                }
            }
        }

        hr
        {
            color: grey; 
            background: grey; 
            border: 0; 
            height: 1px; 
            margin: 10px 0;
        }

        #content-footer{
            display: flex;
            align-items: center;
        }

        #footer-button{
            display: block;
            margin-left: auto;
        }

        #sign-in-with{

            h4 {
                width: 100%; 
                text-align: center; 
                border-bottom: 1px solid grey; 
                line-height: 0.1em;
                margin: 10px 0 20px;

                span { 
                    background:#fff; 
                    padding:0 10px; 
                }
            }

            .sso-buttons{

                button{
                    display: flex;
                    align-items: center;
                    background-color: #ffffff;
                    padding: 0 20px;
                    margin: 10px auto;
                    cursor: pointer;
                    border: 2px solid #eeeeee;
                    border-radius: 5px;

                    img{
                        height: 30px;
                        width: 30px;
                        margin: 5px;
                    }

                    &:hover{
                        background-color: #f3f3f3;
                    }
                }
            }
        }

        .disable-form{
            background-color: #ffffff;
            opacity: 0.5;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            cursor: not-allowed;
        }
    }
}