.dashboard-carousel{
    height: 100%;
    min-height: 250px;
    position: relative;
    
    .user-name-container{
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 20px;
        background: linear-gradient(45deg, rgb(247, 151, 30), #fceabb);
        color: white;
        text-shadow: 0 2px 5px rgba(247, 151, 30, 1);
        display: flex;
        flex-direction: column;
        justify-content: center;
        
        .user-name{
            font-weight: bold;
            font-size: 1.5em;
            animation: moveInBottom 1s ease-out;
        }
        .intro-line{
            font-weight: bold;
            font-size: 0.8em;
            visibility: hidden;
            animation: reveal 1s ease-out 1s forwards;
        }
    }

}

@keyframes moveInBottom {
    0%{
        opacity: 0;
        transform: translateY(30px);
    }
    100%{
        opacity: 1;
        transform: translate(0);
    }
}
@keyframes reveal {
    0%{
        opacity: 0;
    }
    100%{
        opacity: 0.8;
        visibility: visible;
    }
}