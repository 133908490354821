.blade{
    flex: 0 0 80px;
    height: 100vh;
    background: #121212;
    color: #FFFFFF;
    display: flex;
    flex-direction: column;
    transition: flex-basis 0.3s ease-in-out;
    z-index: 10;
    overflow: hidden;

    .blade-logo{
        min-height: 85px;
        max-height: 85px;
        display: flex;
        justify-content: center;
        align-items: center;

        .logo-big{
            max-width: 180px;
            min-width: 180px;
            max-height: 40px;
            min-height: 40px;
        }

        .logo-small{
            max-width: 40px;
            min-width: 40px;
            max-height: 40px;
            min-height: 40px;
        }
    }

    .blade-top{
        width: inherit;
        font-family: Arial, Helvetica, sans-serif;
        overflow-x: hidden;
        overflow-y: auto;

        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;
            width: 100%;

            li{
                display: flex;
                padding: 20px;
                align-items: center;
                cursor: pointer;
                white-space: nowrap;

                .icon{
                    min-width: 25px;
                    min-height: 25px;
                    max-width: 25px;
                    max-height: 25px;
                    fill: rgb(219, 219, 219);
                }

                .label{
                    //padding-left: 20px;
                    display: none;
                }

                &:not(.active){
                    border-right: 10px solid transparent;
                }
            }

            .active {
                border-right: 10px solid #1a73e8;
            }

            li:hover {
                background-color: #2a2a2a;

                .icon{
                    fill: #1a73e8;
                }
            }
        }
    }

    .blade-footer{
        padding: 20px;
        width: 100%;
        margin-top: auto;
        display: flex;
        justify-content: center;

        .right-arrow{
            display: block;
            width: 30px;
            height: 30px;
            fill: rgb(219, 219, 219);

            &:hover{
                fill: #1a73e8;
                cursor: pointer;
            }
        }
    }

    &.expanded{
        flex: 0 0 250px;

        .blade-top{
            ul{
                li{

                    .label{
                        display: block;
                        padding-left: 20px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }
        }

        .blade-footer{
            justify-content: flex-end;
    
            .left-arrow{
                display: block;
                width: 30px;
                height: 30px;
                fill: rgb(219, 219, 219);
    
                &:hover{
                    fill: #1a73e8;
                    cursor: pointer;
                }
            }
        }
    }
}