.avatar{
    width: 44px;
    height: 44px;
    display: flex;
    overflow: hidden;
    position: relative;
    font-size: 1.25rem;
    align-items: center;
    flex-shrink: 0;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    line-height: 1;
    user-select: none;
    border-radius: 50%;
    justify-content: center;
    color: #fafafa;
    background-color: #bdbdbd;
    background-clip: padding-box;
    border: 2px solid transparent;
    margin: 5px;

    img{
        color: transparent;
        width: 110%;
        height: 110%;
        object-fit: cover;
        text-align: center;
        text-indent: 10000px;

        &.img-loading{
            display: none;
        }
    }
}

.avatar-emerald{
    background-color: #2ecc71;
}

.avatar-peter-river{
    background-color: #3498db;
}

.avatar-wisteria{
    background-color: #8e44ad;
}

.avatar-carrot{
    background-color: #e67e22;
}

.avatar-alizarin{
    background-color: #e74c3c;
}

.avatar-turquoise{
    background-color: #1abc9c;
}

.avatar-midnight-blue{
    background-color: #2c3e50;
}