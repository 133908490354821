.page-layout{
    width: 100%;
    height: 100vh;
    display: flex;
    position: relative;
    
    .page-component{
        flex-grow: 1;
        overflow-y: auto;

    }
    .page-nagivation-overlay{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.75);
        z-index: 500;
    }
}
  