.alarm-time{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 20px;
    width: 100%;

    &:hover .checkbox-label, &.checked .checkbox-label {
        visibility: visible;
    }

    /* Styling Checkbox Starts */
    .checkbox-label {
        display: block;
        position: relative;
        cursor: pointer;
        font-size: 22px;
        line-height: 14px;
        height: 14px;
        width: 14px;
        clear: both;

        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
        }
        
        .checkbox-custom {
            position: absolute;
            top: 0px;
            left: 0px;
            height: 14px;
            width: 14px;
            background-color: transparent;
            border-radius: 5px;
            transition: all 0.3s ease-out;
            -webkit-transition: all 0.3s ease-out;
            -moz-transition: all 0.3s ease-out;
            -ms-transition: all 0.3s ease-out;
            -o-transition: all 0.3s ease-out;
            border: 2px solid #ffffff;
        }
        
        input:checked ~ .checkbox-custom {
            background-color: #ffffff;
            border-radius: 5px;
            -webkit-transform: rotate(0deg) scale(1);
            -ms-transform: rotate(0deg) scale(1);
            transform: rotate(0deg) scale(1);
            opacity: 1;
            border: 2px solid #ffffff;
        }
        
        .checkbox-custom::after {
            position: absolute;
            content: "";
            left: 7px;
            top: 7px;
            height: 0px;
            width: 0px;
            border-radius: 5px;
            border: solid #009bff;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(0deg) scale(0);
            -ms-transform: rotate(0deg) scale(0);
            transform: rotate(0deg) scale(0);
            opacity: 1;
            transition: all 0.3s ease-out;
            -webkit-transition: all 0.3s ease-out;
            -moz-transition: all 0.3s ease-out;
            -ms-transition: all 0.3s ease-out;
            -o-transition: all 0.3s ease-out;
        }
        
        input:checked ~ .checkbox-custom::after {
            -webkit-transform: rotate(45deg) scale(1);
            -ms-transform: rotate(45deg) scale(1);
            transform: rotate(45deg) scale(1);
            opacity: 1;
            left: 3px;
            top: 0px;
            width: 2px;
            height: 7px;
            border: solid #009bff;
            border-width: 0 2px 2px 0;
            background-color: transparent;
            border-radius: 0;
        }
        
        /* For Ripple Effect */
        .checkbox-custom::before {
            position: absolute;
            content: "";
            left: 5px;
            top: 5px;
            width: 0px;
            height: 0px;
            border-radius: 5px;
            border: 2px solid #ffffff;
            -webkit-transform: scale(0);
            -ms-transform: scale(0);
            transform: scale(0);
        }
        
        input:checked ~ .checkbox-custom::before {
            left: -3px;
            top: -3px;
            width: 14px;
            height: 14px;
            border-radius: 5px;
            -webkit-transform: scale(3);
            -ms-transform: scale(3);
            transform: scale(3);
            opacity: 0;
            z-index: 999;
            transition: all 0.3s ease-out;
            -webkit-transition: all 0.3s ease-out;
            -moz-transition: all 0.3s ease-out;
            -ms-transition: all 0.3s ease-out;
            -o-transition: all 0.3s ease-out;
        }
        
        .checkbox-custom.circular {
            border-radius: 50%;
            border: 2px solid #dddddd;
        }
        
        input:checked ~ .checkbox-custom.circular {
            background-color: #ffffff;
            border-radius: 50%;
            border: 2px solid #ffffff;
        }

        input:checked ~ .checkbox-custom.circular::after {
            border: solid #0067ff;
            border-width: 0 2px 2px 0;
        }

        .checkbox-custom.circular::after {
            border-radius: 50%;
        }
        
        .checkbox-custom.circular::before {
            border-radius: 50%;
            border: 2px solid #ffffff;
        }
        
        input:checked ~ .checkbox-custom.circular::before {
            border-radius: 50%;
        }
    }

    .toggle-alarm{
        display: flex;
    }

    .switch {
        position: relative;
        display: inline-block;
        width: 40px;
        height: 20px;
    }
      
    .switch input { 
        opacity: 0;
        width: 0;
        height: 0;
    }
      
    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;
    }
      
    .slider:before {
        position: absolute;
        content: "";
        height: 14px;
        width: 14px;
        left: 3px;
        bottom: 3px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
    }
      
    input:checked + .slider {
        background-color: #bada55;
    }
      
    input:focus + .slider {
        box-shadow: 0 0 1px #bada55;
    }
      
    input:checked + .slider:before {
        left: calc(100% - 3px);
        -webkit-transform: translateX(-100%);;
        -ms-transform: translateX(-100%);;
        transform: translateX(-100%);
    }

    .slider.round {
        border-radius: 14px;
    }
      
    .slider.round:before {
        border-radius: 50%;
    }

    .remove-alarm{
        width: 40px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .remove-alarm-button{
            width: 20px;
            height: 20px;
            fill: #262626;
            cursor: pointer;
            
                &:hover{
                fill: #f83f57;
            }
        }
    }

    &.checked{
        background-color: #cccccc;
    }

    @media(hover: hover) and (pointer: fine) {

        .checkbox-label {
            visibility: hidden;
        }

        &:hover .checkbox-label, &.checked .checkbox-label {
            visibility: visible;
        }
    }
}